import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/noahgilmore.com/noahgilmore.com/src/components/BlogPageLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I recently got a bug report for `}<a parentName="p" {...{
        "href": "https://apps.apple.com/app/apple-store/id1533210346?pt=118551115&ct=uibpbp&mt=8"
      }}>{`Transparent App Icons`}</a>{` to the effect of "I tried to paste in a transparent image, but it didn't work." After cropping the background to create a "transparent" image users can use for their Shortcuts icons, the app allows the user to paste an image from the clipboard to overlay and create a semi-transparent icon - this user was trying to paste a WebP image.`}</p>
    <p>{`Turns out that `}<a parentName="p" {...{
        "href": "https://developers.google.com/speed/webp"
      }}>{`WebP`}</a>{`, the web-optimized image format developed by Google, isn't supported natively by UIKit. Luckily it's not too hard to implement support for it with the help of a small library and some deeper UIKit APIs.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Looking for a `}<inlineCode parentName="p">{`UIPasteboard`}</inlineCode>{` extension which loads WebP images? Skip to the bottom!`}</p>
    </blockquote>
    <h1 {...{
      "id": "uipasteboard-data"
    }}>{`UIPasteboard data`}</h1>
    <p>{`I haven't had to use the more complicated parts of `}<inlineCode parentName="p">{`UIPasteboard`}</inlineCode>{` very much in my time as an iOS developer - in `}<a parentName="p" {...{
        "href": "https://apps.apple.com/app/apple-store/id1533210346?pt=118551115&ct=uibpbp&mt=8"
      }}>{`Transparent App Icons`}</a>{`, I was just checking `}<inlineCode parentName="p">{`UIPasteboard.general.image`}</inlineCode>{` in order to figure out whether there was an image copied. In iOS 14, you can copy a WebP image no problem, but there's no `}<inlineCode parentName="p">{`UIImage`}</inlineCode>{` initializer representation for it, and as such it doesn't get reported as a `}<inlineCode parentName="p">{`UIImage`}</inlineCode>{` in the same way it would if you had copied a PNG.`}</p>
    <p>{`Luckily, there's a higher-effort, higher-reward way to interact with `}<inlineCode parentName="p">{`UIPasteboard`}</inlineCode>{`. When the user copies data, the app it copied from might choose to write multiple `}<em parentName="p">{`representations`}</em>{` of the data to the pasteboard - for example, if I'm writing an HTML editor and the user copies part of the document, I could write both HTML and string repreesentations to the pasteboard. Then, an app like notes doesn't have to worry about the HTML representation, it can just read the string value.`}</p>
    <p>{`Each representation of an object in the pasteboard is denoted by a type, usually a `}<a parentName="p" {...{
        "href": "https://developer.apple.com/documentation/uniformtypeidentifiers/uttype/system_declared_types"
      }}>{`Uniform Type Identifier`}</a>{`. We can request the raw data for a pasteboard item using `}<inlineCode parentName="p">{`UIPasteboard`}</inlineCode>{`'s' `}<a parentName="p" {...{
        "href": "https://developer.apple.com/documentation/uikit/uipasteboard/1622093-data"
      }}><inlineCode parentName="a">{`data(forPasteboardType:)`}</inlineCode></a>{` method, and since iOS 14 there's even a more strongly typed way, using `}<inlineCode parentName="p">{`UTType`}</inlineCode>{` - `}<a parentName="p" {...{
        "href": "https://developer.apple.com/documentation/uniformtypeidentifiers/uttype/3551599-webp"
      }}>{`here's the `}<inlineCode parentName="a">{`UTType`}</inlineCode>{` identifier for WebP`}</a>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Note: When I was trying to figure this out, I first tried using `}<inlineCode parentName="p">{`loadItem`}</inlineCode>{` on the pasteboard's `}<inlineCode parentName="p">{`itemProviders`}</inlineCode>{`, but this returns a `}<inlineCode parentName="p">{`URL`}</inlineCode>{` that you can't read the data of since it's restricted (annoyingly, only on device will you get permission denied errors - this URL is perfectly readable on simulator). I then moved on to `}<inlineCode parentName="p">{`loadData`}</inlineCode>{`, before realizing I could just use `}<inlineCode parentName="p">{`data(forPasteboardType:)`}</inlineCode>{` directly.`}</p>
    </blockquote>
    <h1 {...{
      "id": "parsing-webp"
    }}>{`Parsing WebP`}</h1>
    <p>{`Once we have the data, we'll need to convert it into a `}<inlineCode parentName="p">{`UIImage`}</inlineCode>{` - `}<inlineCode parentName="p">{`UIImage`}</inlineCode>{` doesn't support this out of the box, but luckily `}<a parentName="p" {...{
        "href": "https://twitter.com/mattt"
      }}>{`Mattt`}</a>{` has a `}<a parentName="p" {...{
        "href": "https://github.com/mattt/WebPImageSerialization"
      }}>{`great little library`}</a>{` for encoding/decoding `}<inlineCode parentName="p">{`UIImage`}</inlineCode>{`s with WebP. Taking that with our data representation, we can write an extension to load a WebP image if one exists:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`extension`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`UIPasteboard`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`loadImage`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() -> UIImage? {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`if`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`types`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`contains`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(UTType.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`webP`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`identifier`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`),`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`           `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` provider = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`itemProviders`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`first`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`           `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`image`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` == `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`nil`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`           `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` data = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`data`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`forPasteboardType`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: UTType.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`webP`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`identifier`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`do`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` webpImage = `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`try`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` WebPImageSerialization.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`image`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`with`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: data)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` webpImage`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            } `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`catch`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// Log error`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`nil`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` UIPasteboard.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`general`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`image`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`This ended up working great, and Transparent App Icons will support WebP in the coming version.`}</p>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .dark-default-dark {
background-color: #1E1E1E;
color: #D4D4D4;
}

.dark-default-dark .mtk1 { color: #D4D4D4; }
.dark-default-dark .mtk2 { color: #1E1E1E; }
.dark-default-dark .mtk3 { color: #6A9955; }
.dark-default-dark .mtk4 { color: #569CD6; }
.dark-default-dark .mtk5 { color: #D16969; }
.dark-default-dark .mtk6 { color: #D7BA7D; }
.dark-default-dark .mtk7 { color: #B5CEA8; }
.dark-default-dark .mtk8 { color: #CE9178; }
.dark-default-dark .mtk9 { color: #646695; }
.dark-default-dark .mtk10 { color: #4EC9B0; }
.dark-default-dark .mtk11 { color: #DCDCAA; }
.dark-default-dark .mtk12 { color: #9CDCFE; }
.dark-default-dark .mtk13 { color: #000080; }
.dark-default-dark .mtk14 { color: #F44747; }
.dark-default-dark .mtk15 { color: #C586C0; }
.dark-default-dark .mtk16 { color: #6796E6; }
.dark-default-dark .mtk17 { color: #808080; }
.dark-default-dark .mtki { font-style: italic; }
.dark-default-dark .mtkb { font-weight: bold; }
.dark-default-dark .mtku { text-decoration: underline; text-underline-position: under; }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      